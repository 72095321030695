import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
} from '@chakra-ui/react'
import Head from 'next/head'

import ActionButtonDrawer from 'components/action-button-drawer'
import AddButton from 'components/add-button'
import PortraitCollapseButton from 'components/buttons/portrait-collapse-button'
import ColorPicker from 'components/color-picker'
import DDWOption from 'components/ddw-database-options'
import LampPicker from '../components/lamp-model-picker'
import LampViewer from 'components/lamp-viewer'
import ShapeDefiner from 'components/shape-definer'
import NewShapeSelector, {
  getInitialShapes,
} from 'components/new-shape-selector'

import { constants, modelTypeFlags } from 'lib/constants'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import ConfigPanel from 'components/config-panel'
import SurfaceStructurePicker from 'components/surface-structure-picker'
import { generateIndexPageHooks } from 'pages/index-functions'

import LoadingNotifier from 'components/loading-notifier'

import { useEffect } from 'react'
import PriceDisplay from 'components/price-display'

import Header from 'components/header'

export default function Home() {
  const {
    geometryState,
    websiteState,
    router,
    lampSetter,
    meshSetter,
    canvasRef,
    canvasSize,
  } = generateIndexPageHooks()

  useEffect(() => {
    // console.log(hashPassword('something'))
  }, [])

  return (
    <div>
      <Header color={geometryState.color} />

      <Flex
        p={'15px'}
        height={websiteState.hasMounted ? window.innerHeight : '100vh'}
        fontSize={{ base: '0.5em', md: '1em' }}
        direction={{ base: 'column', md: 'row' }}
        alignItems={'center'}
      >
        {/* <MenuButton></MenuButton> */}

        {websiteState.showLoader && canvasRef.current && <LoadingNotifier />}
        <Head>
          <title>Lyla Design</title>
          <meta
            name='description'
            content='Make your own 3D printable designs'
          />
          <link rel='icon' href='/favicon.ico' />
        </Head>

        <LampViewer
          canvasRef={canvasRef}
          socketColor={'0x000000'}
          geometryState={geometryState}
          websiteState={websiteState}
        />

        <Flex
          alignSelf={'center'}
          alignItems={'flex-start'}
          height={{ base: 'unset', md: '100vh' }}
          paddingTop={{ base: '0', md: '15vh' }}
          direction={{ base: 'column', md: 'row' }}
          paddingRight={{
            base: '0em',
            md: websiteState.sidebarIsOpen ? '8em' : '0em',
          }}
          transition='all 1s ease'
        >
          <ActionButtonDrawer
            etsy={false}
            router={router}
            geometryState={geometryState}
            websiteState={websiteState}
          />

          <Flex
            alignSelf={'center'}
            alignItems={'flex-start'}
            height={'100%'}
            direction={{ base: 'column', md: 'row' }}
            overflowY={'scroll'}
            sx={{
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Accordion
              color={pickTextColorBasedOnBgColor(geometryState.color)}
              allowMultiple={true}
              justifyContent={'center'}
              width={{
                base: '70vw',
                md: websiteState.sidebarIsOpen ? '20vw' : '0px',
              }}
              minWidth={{
                base: '200px',
                md: websiteState.sidebarIsOpen ? '400px' : '0px',
              }}
              minHeight={'unset'}
              maxHeight={{
                base: websiteState.sidebarIsOpen ? '100vh' : '4em',
                md: 'unset',
              }}
              overflow={'hidden'}
              defaultIndex={0}
              bgColor={'#' + geometryState.color}
              rounded={'lg'}
              boxShadow={'lg'}
              allowToggle={true}
            >
              {!websiteState.isLandscape && (
                <Center marginTop={'1em'}>
                  <PortraitCollapseButton
                    setSidebarIsOpen={websiteState.setSidebarIsOpen}
                    sidebarOpen={websiteState.sidebarIsOpen}
                  />
                </Center>
              )}

              <Center>
                <LampPicker
                  geometryState={geometryState}
                  websiteState={websiteState}
                />
              </Center>

              <Center>
                <ColorPicker
                  color={geometryState.color}
                  setColor={geometryState.setColor}
                />
              </Center>

              <AccordionItem
                borderColor={pickTextColorBasedOnBgColor(
                  geometryState.color,
                  constants.colors.semiTransparentWhite,
                  constants.colors.semiTransparentBlack
                )}
              >
                <AccordionButton>
                  <Flex flex='1' textAlign='left' justifyContent={'center'}>
                    <Flex width={{ base: '85%', md: '87%' }} fontSize={'1em'}>
                      SHAPE
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel
                  display={'flex'}
                  flexDirection={'column'}
                  paddingX={0}
                  width='100%'
                  alignItems={'center'}
                  justifyContent={'center'}
                  color='black'
                >
                  <ShapeDefiner
                    geometryState={geometryState}
                    websiteState={websiteState}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem
                borderColor={pickTextColorBasedOnBgColor(
                  geometryState.color,
                  constants.colors.semiTransparentWhite,
                  constants.colors.semiTransparentBlack
                )}
              >
                <AccordionButton>
                  <Flex flex='1' textAlign='left' justifyContent={'center'}>
                    <Flex width={{ base: '85%', md: '87%' }}>SURFACE</Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel
                  display={'flex'}
                  paddingX={0}
                  width='100%'
                  color='black'
                  justifyContent={'center'}
                >
                  <SurfaceStructurePicker
                    geometryState={geometryState}
                    websiteState={websiteState}
                  />
                </AccordionPanel>
              </AccordionItem>

              {geometryState.modelType.flags.includes(
                modelTypeFlags.shouldShowAdvancedEditor
              ) && (
                <AccordionItem
                  borderColor={pickTextColorBasedOnBgColor(
                    geometryState.color,
                    constants.colors.semiTransparentWhite,
                    constants.colors.semiTransparentBlack
                  )}
                >
                  <AccordionButton>
                    <Flex flex='1' textAlign='left' justifyContent={'center'}>
                      <Flex width={{ base: '85%', md: '87%' }}>
                        ADVANCED EDITOR
                      </Flex>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>

                  <Center>
                    <AccordionPanel
                      pb={4}
                      overflowY={'scroll'}
                      sx={{
                        '::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                      color='black'
                      maxHeight={{ base: '30vh', md: '50vh' }}
                    >
                      {websiteState.hasMounted
                        ? geometryState.profiles.intermediateShapes.map(
                            (value, index) => {
                              return (
                                <NewShapeSelector
                                  geometryState={geometryState}
                                  websiteState={websiteState}
                                  borderColor={pickTextColorBasedOnBgColor(
                                    geometryState.color,
                                    constants.colors.semiTransparentWhite,
                                    constants.colors.semiTransparentBlack
                                  )}
                                  key={websiteState.randomSalt + ' ' + index}
                                  index={index + 1}
                                />
                              )
                            }
                          )
                        : false}

                      {geometryState.profiles.intermediateShapes.length ==
                        0 && (
                        <AddButton
                          color={geometryState.color}
                          editorWidth={constants.editorWidth / 2}
                          profileHook={[
                            geometryState.profiles,
                            geometryState.setProfiles,
                          ]}
                        ></AddButton>
                      )}

                      <NewShapeSelector
                        geometryState={geometryState}
                        websiteState={websiteState}
                        key={websiteState.randomSalt + '-1'}
                        borderColor={pickTextColorBasedOnBgColor(
                          geometryState.color,
                          constants.colors.semiTransparentWhite,
                          constants.colors.semiTransparentBlack
                        )}
                        index={-1}
                      />
                    </AccordionPanel>
                  </Center>
                </AccordionItem>
              )}

              <AccordionItem
                borderColor={pickTextColorBasedOnBgColor(
                  geometryState.color,
                  constants.colors.semiTransparentWhite,
                  constants.colors.semiTransparentBlack
                )}
              >
                <AccordionButton>
                  <Flex flex='1' textAlign='left' justifyContent={'center'}>
                    <Flex width={{ base: '85%', md: '87%' }}>ORDER</Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <Center>
                  <AccordionPanel
                    display={'flex'}
                    paddingX={100}
                    width='100%'
                    color='black'
                    justifyContent={'center'}
                  >
                    <PriceDisplay
                      websiteState={websiteState}
                      geometryState={geometryState}
                      borderColor={pickTextColorBasedOnBgColor(
                        geometryState.color,
                        constants.colors.semiTransparentWhite,
                        constants.colors.semiTransparentBlack
                      )}
                    />
                  </AccordionPanel>
                </Center>
              </AccordionItem>

              <AccordionItem
                borderColor={pickTextColorBasedOnBgColor(
                  geometryState.color,
                  constants.colors.semiTransparentWhite,
                  constants.colors.semiTransparentBlack
                )}
              >
                <AccordionButton>
                  <Flex flex='1' textAlign='left' justifyContent={'center'}>
                    <Flex width={{ base: '85%', md: '87%' }}>CONFIG</Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  display={'flex'}
                  flexDirection={'column'}
                  paddingX={0}
                  width='100%'
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <ConfigPanel
                    websiteState={websiteState}
                    geometryState={geometryState}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem
                borderColor={pickTextColorBasedOnBgColor(
                  geometryState.color,
                  constants.colors.semiTransparentWhite,
                  constants.colors.semiTransparentBlack
                )}
              >
                <Center>
                  <DDWOption
                    router={router}
                    websiteState={websiteState}
                    geometryState={geometryState}
                    borderColor={pickTextColorBasedOnBgColor(
                      geometryState.color,
                      constants.colors.semiTransparentWhite,
                      constants.colors.semiTransparentBlack
                    )}
                  />
                </Center>
              </AccordionItem>
            </Accordion>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
