import { Flex, Link, Box, textDecoration } from '@chakra-ui/react'
import { FaInstagram, FaLinkedin } from 'react-icons/fa'
import UserInfo from 'components/userinfo'
import styles from '../../apps/editor/styles/Header.module.css'
import { websiteURL } from 'lib/constants'

export default function Header({ color }: { color: string }) {
  const URL = process.env.WEBSITE_URL || websiteURL
  return (
    <>
      <Flex
        alignSelf={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        direction={'row'}
        paddingTop={'2vh'}
        paddingLeft={{ base: '0.5vh' }}
        position='fixed'
        zIndex={1}
        width={{ base: '100vw', md: '100vw' }}
      >
        <Flex
          justifyContent='center'
          alignSelf={'center'}
          alignItems={'center'}
          marginRight={{ base: '3.5vw', md: '5vw' }}
          className={styles.headerLogoFont}
          fontSize={{ base: 15, md: 23, lg: 28, xl: 33 }}
        >
          <Link href={URL}> LYLA</Link>
        </Flex>

        <Flex
          className={styles.headerFont}
          fontSize={{ base: 7, md: 9, lg: 10, xl: 13 }}
          marginRight={{ base: '1vw', md: '5vw' }}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={{ base: '95vw', md: '60vw' }}
        >
          <Link
            marginRight={{ base: '1vw', md: '1vw' }}
            textDecor={'underline'}
          >
            PLAYGROUND
          </Link>

          <Link marginRight={{ base: '1vw', md: '1vw' }} href={URL + '/shop/'}>
            PRET A PORTER
          </Link>

          <Link
            marginRight={{ base: '1vw', md: '1vw' }}
            href={URL + '/artists/'}
          >
            ARTISTS
          </Link>

          <Link
            marginRight={{ base: '1vw', md: '1vw' }}
            href={URL + '/principles/'}
          >
            ABOUT US
          </Link>

          <Link
            marginRight={{ base: '1vw', md: '1vw' }}
            href='https://drive.google.com/drive/folders/1phVg7389_2J84nOLD0FQ-KYJ2k1iZ5cL'
          >
            DOWNLOADS
          </Link>
          <Link
            className={styles.loginFont}
            fontSize={{ base: 8, md: 10, lg: 11, xl: 14 }}
          >
            <UserInfo />
          </Link>
        </Flex>

        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          marginRight={{ base: '0vw', md: '5vw' }}
          color={color}
        >
          <Flex
            fontSize={{ base: '15px', md: '20px', lg: '22px', xl: '23px' }}
            _hover={{ cursor: 'pointer' }}
            paddingRight={{ base: '1', md: '2' }}
            onClick={() => {
              window.open('https://www.instagram.com/lyla_design_eu/', '_blank')
            }}
          >
            <Box>
              <FaInstagram />
            </Box>
          </Flex>
          <Flex
            _hover={{ cursor: 'pointer' }}
            fontSize={{ base: '15px', md: '20px', lg: '22px', xl: '23px' }}
            onClick={() => {
              window.open(
                'https://www.linkedin.com/company/lyladesign/',
                '_blank'
              )
            }}
          >
            <Box>
              <FaLinkedin />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
